<template>
  <vx-card style="padding-left: 10px">
    <div class="w-full flex justify-end">
      <vs-button
        align="right"
        @click="createHandler"
        icon-pack="feather"
        icon="icon-edit"
        >Add</vs-button>
    </div>

    <div class="tier-package-container">
      <vs-table
        ref="table"
        :sst="true"
        :data="docs"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between py-4">
          <vs-select
            placeholder="10"
            vs-multiple
            autocomplete
            :disabled="!serverResponded"
            v-model="dataTableParams.limit"
            class="mr-6"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in limitOptions"
              :clearable="false"
            />
          </vs-select>
          <vs-input
            icon="search"
            class="search-input"
            :disabled="!serverResponded"
            v-model="dataTableParams.search"
            @input="handleSearch"
            placeholder="Search..."
          />
        </div>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>GFE Consultation Fee</vs-th>
          <vs-th>Created At</vs-th>
          <vs-th>
            <vx-tooltip text="# of clinics using this package" position="top">
              <div class="flex justify-center items-center">
                # of clinics&nbsp;
                <vs-icon
                  icon="help_outline"
                  size="16px"
                  bg="#0000"
                  round
                ></vs-icon>
              </div>
            </vx-tooltip>
          </vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>
            <vs-td :data="data[indextr].gfeConsultationFee">
              {{ data[indextr].gfeConsultationFee | formatDecimals }}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>
            <vs-td :data="data[indextr]._id">{{ (data[indextr].clinicInfo || []).length }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Tier Package" position="left">
                <vs-button
                  type="border"
                  size="small"
                  @click="editHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="mr-2"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Delete Tier Package" position="left">
                <vs-button
                  type="border"
                  size="small"
                  :disabled="!!(data[indextr].clinicInfo || []).length"
                  @click="openDeletePopup(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-trash"
                  class="mt-1"
                  color="danger"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="flex justify-end">
        <span class="mr-2"></span>
        <pagination
          class="mt-3"
          :isFetching="!serverResponded"
          :hasPrev="hasPrev"
          :hasNext="hasNext"
          @handleOnChangePage="handleChangePage"/>
      </div>

      <vs-popup :active.sync="popUpDelete" title="Delete Tier Package">
        <div class="p-6">
          <p class="text-center text-black text-lg font-medium mb-6">Are you sure you want to delete this tier package?</p>
          <div class="flex items-center justify-between space-x-4">
            <vs-button
              size="lg"
              color="primary"
              class="flex-1"
              @click="deleteDetailHandler"
            >Yes</vs-button>
            <vs-button class="flex-1" size="lg" color="danger" @click="popUpDelete = false">Cancel</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import Pagination from "@/views/general-component/pagination.vue";
import { debounce } from "lodash";

export default {
  name: "TierPackageList",
  components: {
    Pagination
  },
  data() {
    return {
      popUpDelete: false,
      selectedId: '',
      noDataText: "Loading...",
      serverResponded: false,
      hasPrev: false,
      hasNext: false,
      docs: [],
      dataTableParams: {
        search: "",
        sort: "_id",
        dir: "desc",
        page: 1,
        limit: 10
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
    };
  },
  methods: {
    ...mapActions("tierPackage", [
      "listTierPackage",
      "deleteTierPackage"
    ]),
    createHandler() {
      this.$router.push({ name: "SuperAdminTierPackageCreate" });
    },
    editHandler(id) {
      this.$router.push({ name: "SuperAdminTierPackageEdit", params: { id } });
    },
    async fetchList(options = {}, refetch = false) {
      if (!refetch) this.$vs.loading();

      this.docs = []
      this.noDataText = 'Loading...'
      try {
        const res = await this.listTierPackage({
          ...this.dataTableParams,
          ...options
        })
        this.docs = res.data.data.docs;
        this.hasNext = res.data.data.hasNext
        this.hasPrev = res.data.data.hasPrev
        this.noDataText = "No data available";

        if (options.page) self.dataTableParams.page = options.page
      } catch (err) {
        console.log('error fetching:: ', err)
      } finally {
        if (!refetch) this.$vs.loading.close();
        this.serverResponded = true
      }
    },
    handleSearch: debounce(function (searchString) {
      this.serverResponded = false;
      this.$refs.table.currentx = 1;
      this.fetchList({
        search: searchString,
        page: 1
      });
    }, 500),
    handleChangePage(page) {
      let payload = {
        next: true,
        id: this.docs[this.docs.length - 1]._id,
        page: this.dataTableParams.page + 1
      }

      if (page === 'prev') {
        payload = {
          prev: true,
          id: this.docs[0]._id,
          page: this.dataTableParams.page - 1
        }
      }

      this.serverResponded = false;
      this.fetchList(payload)
    },
    openDeletePopup(id){
      this.selectedId = id;
      this.popUpDelete = true;
    },
    async deleteDetailHandler() {
      this.$vs.loading();
      try {
        this.$refs.table.currentx = 1;
        this.dataTableParams.page = 1;
        this.serverResponded = false;
        this.popUpDelete = false

        await this.deleteTierPackage(this.selectedId)

        this.selectedId = null

        this.$vs.notify({
          title: "Success",
          text: "Tier package deleted successfully",
          color: "success",
        });

        await this.fetchList({}, true)
      } catch (err) {
        console.log('error deleting:: ', err)

        this.$vs.notify({
          title: "Unsuccessful",
          text: 'Failed to delete tier package.',
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      } finally {
        this.$vs.loading.close();
      }
    },
},
  watch: {
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    }
  },
  created() {
    this.fetchList();
  }
};
</script>

<style lang="scss">
.tier-package-container {
  .search-input {
    .vs-input--input {
      border-radius: 20px;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
      }
    }

    .vs-con-input {
      .vs-input--icon {
        top: auto;
        left: 8px;
        border-right: none;
      }
    }
  }
}
</style>